var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"transaction-card-label"},[(!_vm.isOnAddManualTransaction)?[(_vm.showCreationSummary)?_c('div',{staticClass:"transaction-card-label-form"},[_c('v-text-field',{staticClass:"transaction-card-summary flex-grow-1",attrs:{"rules":[function () { return !!_vm.newSummary || 'Le champ ne peut pas être vide'; }],"dense":"","hide-details":"auto","placeholder":"libellé","required":"","type":"text"},model:{value:(_vm.newSummary),callback:function ($$v) {_vm.newSummary=$$v},expression:"newSummary"}}),_c('div',{staticClass:"flex-sm-grow-1"},[_c('v-btn',{attrs:{"color":"#000","icon":"","small":"","type":"submit"},on:{"click":function($event){_vm.showCreationSummary = false}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"color":"primary","icon":"","small":"","type":"submit"},on:{"click":_vm.validateNewSummary}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1)],1)],1):[_c('p',{staticClass:"transaction-card-summary mb-0 mr-10"},[_vm._v(" "+_vm._s(_vm.summary)+" ")]),(!_vm.isDisableUpdateSummary)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-md-2",attrs:{"disabled":_vm.readonly,"id":"track-change_name","color":"#000","icon":"","small":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickAddSummary($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline")])],1)]}}],null,false,2986068661)},[_c('span',[_vm._v("Ajouter un libellé")])]):_vm._e(),(_vm.isErasable && !_vm.isDisableRemove)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-md-2",attrs:{"disabled":_vm.readonly,"color":"#000","icon":"","small":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickDeleteTransaction($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline")])],1)]}}],null,false,3268665510)},[_c('span',[_vm._v("Supprimer cette transaction")])]):_vm._e()],(
          _vm.transactionState.savedCategories.length !== 0 &&
          _vm.transactionState.transaction.value.amount !== null
        )?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-md-2",attrs:{"disabled":_vm.readonly,"color":"#000","icon":"","small":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.clickDuplicateTransaction($event)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-content-duplicate")])],1)]}}],null,false,4007149615)},[_c('span',[_vm._v("Dupliquer la transaction")])]):_vm._e()]:_vm._e(),(_vm.isOnAddManualTransaction)?[_c('p',{staticClass:"transaction-card-summary mb-0 mr-10"},[_vm._v(" "+_vm._s(_vm.transactionState.transaction.summary)+" ")])]:_vm._e(),(_vm.getChipTitle(_vm.transactionState.transaction))?_c('v-chip',{staticClass:"chip",attrs:{"small":"","color":"blue","outlined":""}},[_vm._v(" "+_vm._s(_vm.getChipTitle(_vm.transactionState.transaction)))]):_vm._e()],2),(!_vm.$vuetify.breakpoint.mdAndUp || _vm.isOnAddManualTransaction)?_c('div',{staticClass:"transaction-card-amount"},[(_vm.transactionState.transaction.value)?_c('span',[_vm._v(" "+_vm._s(_vm.$n(_vm.transactionState.transaction.value.amount, "currency", "fr-FR"))+" ")]):_vm._e()]):_vm._e(),_c('Confirm',{ref:"confirmDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }